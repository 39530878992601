.info {
  display: flex;
  flex-direction: row;
  height: 100vh;
  word-break: break-word;
  overflow: hidden;
}

.info-left {
  flex: 1;
  max-width: 50vw;
  display: flex;
  flex-direction: column;
}

.info-right {
  flex: 1;
  max-width: 50vw;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
}

.info-header {
  padding: 1rem 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-header .logo {
  height: 14vh;
}

.info-header .host-image {
  height: 14vh;
}

.info-header .qr-image {
  height: 14vh;
  background: white;
}

.track-image {
  width: 50%;
}

.info-track {
  flex: 1;
  padding: 1rem;
  font-size: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-track img {
  width: 50%;
  margin-bottom: 1rem;
  background-color: white;
}