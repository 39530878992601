html {
  font-size: 2.3vh;
  width: 100%;
}

body {
  background-color: #000000;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  color: #FFFFFF;
}

#root {
  width: 100%;
}

.App {
}
