.instant-info {
  flex: 1;
  display: flex;
  flex-direction: column; 
}

.instant-info-option {
  max-width: 100%;
  display: flex;
  flex: 1;
}

.instant-info-option img {
  object-fit: contain;
}

.instant-info-option-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 0 1rem;
  flex: 1;
  overflow: hidden;
}

.instant-info-option-name {
  font-size: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.instant-info-option-votes {
  text-align: center;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 2.5rem;
}

.instant-info-option-votes-number {
  font-size: 2rem;
}

.instant-info-option-votes-text {
  word-break: normal
}
