.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login .logo {
  height: 14vh;
  margin-bottom: 2rem;
}

.login .button {
  margin-bottom: 2rem;
}
